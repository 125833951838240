<template>
  <v-dialog v-model="showDialog" class="justify-center layout px-0" max-width="468px">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <mf-button :label="buttonName" outlined color="primary" />
      </span>
    </template>
    <v-card style="overflow-x:hidden">
      <v-card-title>
        Selecione um arquivo .csv
      </v-card-title>
      <v-card-text>
        <form enctype="multipart/form-data" class="ma-4">
          <v-file-input
            accept=".csv"
            show-size
            small-chips
            outlined
            label="Planilha .csv"
            placeholder="Clique para selecionar"
            :disabled="disabled"
            @change="onFileChange"
          />
        </form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <mf-button label="Cancelar" outlined color="error" @click="cancel" />
      </v-card-actions>
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    buttonName: {
      type: String,
      default: 'Importar .CSV'
    }
  },
  data: () => ({
    showDialog: false,
    loading: false
  }),
  methods: {
    cancel() {
      this.showDialog = false
      this.loading = false
    },
    async onFileChange(file) {
      if (!file) {
        this.$emit('clear')
        return
      }
      const reader = new FileReader()
      reader.onloadstart = _ => {
        this.loading = true
      }
      reader.onload = e => {
        this.loading = false
        const readed = e.target.result.toString()
        this.$emit('done', { readed: readed || [], fileName: file.name })
      }
      reader.readAsText(file)
      this.showDialog = false
    }
  }
}
</script>
